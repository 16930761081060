/*
@import '../node_modules/@syncfusion/ej2-base/styles/material.css';
@import '../node_modules/@syncfusion/ej2-buttons/styles/material.css';
@import '../node_modules/@syncfusion/ej2-calendars/styles/material.css';
@import '../node_modules/@syncfusion/ej2-dropdowns/styles/material.css';
@import '../node_modules/@syncfusion/ej2-inputs/styles/material.css';
@import '../node_modules/@syncfusion/ej2-navigations/styles/material.css';
@import '../node_modules/@syncfusion/ej2-notifications/styles/material.css';
@import '../node_modules/@syncfusion/ej2-popups/styles/material.css';
@import '../node_modules/@syncfusion/ej2-react-buttons/styles/material.css';
@import '../node_modules/@syncfusion/ej2-react-grids/styles/material.css';
@import '../node_modules/@syncfusion/ej2-react-popups/styles/material.css';
@import '../node_modules/@syncfusion/ej2-splitbuttons/styles/material.css';
@import '../node_modules/@syncfusion/ej2-icons/styles/material.css';
*/
@import '../node_modules/@syncfusion/ej2-base/styles/material.css';
@import '../node_modules/@syncfusion/ej2-react-notifications/styles/material.css';

@import 'theme/material.css';

.app {
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.app-header {
  flex-shrink: 0;
}

.app-body {
  margin-top: 16px;
  margin-right: 16px;
  margin-left: 16px;
  margin-bottom: 16px;
  flex: 1 0 auto;
}

.alignment-table-container {
  display: flex;
  flex-direction: column;
  align-items: center; /* or 'center' if you want the items centered horizontally */
  gap: 10px;
}

.alignment-table-header {
  display: flex;
  gap: 8px;
}

.filter-container {
  margin-top: 16px;
  margin-right: 64px;
  margin-left: 64px;
  flex: 1 0 auto;
}

.grid-container {
  margin-top: 16px;
  margin-right: 16px;
  margin-left: 16px;
  flex: 1 0 auto;
}

.app-footer {
  display: none;
  font-size: 0.75rem;
  font-style: italic;
  flex-shrink: 0;
}

.non-selectable {
  cursor: not-allowed;
}

.dialogContent {
  border-style: none;
  padding: 8px;
}

.dialogLabel {
  padding-right: 60px;
}
